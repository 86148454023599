import Validator from 'parsleyjs/dist/parsley.min.js';
import intlTelInput from 'intl-tel-input/build/js/intlTelInput-jquery.min.js';
import 'intl-tel-input/build/js/utils.js';
import select2 from 'select2';

$(document).ready(function() {
  $('#user_profile_first_name, #user_profile_last_name').bind('keyup',function() {
    $(this).val($(this).val().replace(/[^a-zéèêçïäöüßńłí ]/i, ""));
  });
  $('#user_profile_phone').intlTelInput({
    initialCountry: 'US',
    utilsScript: 'intl-tel-input/build/js/utils.js'
  });
  
  window.Parsley.addValidator('intlphone', {
    validateString: function(value) {
      return $("#user_profile_phone").intlTelInput("isValidNumber");
    },
    messages: {
      en: 'Please enter a valid phone number.'
    }
  });
  
  $("#continue-email").click(function() {
    $('#login-notices,#login-errors').hide();
    var $form = $('#new_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $.post('/users/check', $('#new_user').serialize(), function(response) {
        if (response) {
          $("#email-text").html("Please enter your Guest Tickets Express password for {email}.".replace(/{email}/, $("#user_email").val()));
          $("#password-block").show();
          $("#email-block").hide();
          $("#login-email").click(function() {
            $("#new_user").submit();
          }); 
        } else {
          $("#registerModal").modal('show');
        }
      });
  });
  $('#reset-email').click(function(){
    var $form = $('#new_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $("#new_user").submit();
  });
  $('#change-password').click(function(){
    var $form = $('#new_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $("#new_user").submit();
  });
  $('#resend-instructions').click(function(){
    var $form = $('#new_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $("#new_user").submit();
  });
  $('#update-account').click(function(){
    var $form = $('#edit_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $("#edit_user").submit();
  });
  $("#create-account").click(function(){
    var $form = $('#new_user').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid())
      $("#new_user").submit();
  });
  $("#update-profile").click(function(){
    var $form = $('form.edit_user_profile').parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select',
    });
    $form.validate();
    if($form.isValid()) {
      $('#user_profile_phone').val($('#user_profile_phone').intlTelInput("getNumber"));
      $("form.edit_user_profile").submit();
    }
  });
  
  $('#q_created_at_gteq_datetime').datepicker({
      startDate: '-90d',
      format: 'mm/dd/yyyy',
      autoclose: true,
      zIndexOffset: 10000
  });
  
  $('#q_created_at_lteq_datetime').datepicker({
        startDate: '-90d',
        endDate: '+0d',
        format: 'mm/dd/yyyy',
        autoclose: true,
        zIndexOffset: 10000
  });  
  
  $('#q_filter_by_product_date_gteq_datetime ').datepicker({
      startDate: '0d',
      format: 'mm/dd/yyyy',
      autoclose: true,
      zIndexOffset: 10000
  });
  
  $('#q_filter_by_product_date_lteq_datetime ').datepicker({
        startDate: '0d',
        endDate: '+90d',
        format: 'mm/dd/yyyy',
        autoclose: true,
        zIndexOffset: 10000
  });  
  
  $(".js-select-3").select2({minimumResultsForSearch: -1}).on("change", function () {  
    $('#' + $(this).attr('id').replace(/_f/,'')).attr('name','q[' + $(this).val() + ']');
  });
});